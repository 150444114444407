import { API } from "../../config";
import styled from "styled-components";

import { Grid, Typography, useTheme } from "@mui/material";
const ReSizeImg = styled.img`
  display: block;
  object-fit: cover;
  height: 6rem;
  width: 6rem;
  border-radius: 5px;
  margin: 0 auto;
  transition: 500ms;
  &:hover {
    transform: scale(1.1);
  }
`;

function linkURLResolver(comType) {
  if (comType === API.ComType.MAIN_RECENT_PLAYER) {
    return "/player-detail";
  } else if (comType === API.ComType.MAIN_RECENT_COACH) {
    return "/coach-detail";
  }
}
export default function PlayerCard({ data, isSelected, setSelectedPlayer }) {
  const theme = useTheme();
  return (
    <div
      style={{
        padding: "0.5rem",
        border: "1px solid #999999",
        margin: "0 0 0 1rem",
        opacity: isSelected ? 1 : 0.5,
        cursor: "pointer",
      }}
      onClick={() => {
        setSelectedPlayer((prev) => data);
      }}
    >
      <Grid container>
        <Grid
          item
          xs={4}
          md={5}
          sx={{
            overflow: "hidden",
            height: "6.25rem",
            width: "6.25rem",
            borderRadius: "5px",
          }}
        >
          <ReSizeImg
            src={
              data.profileImage
                ? `${API.BASE}/${data.profileImage.fileUrl}`
                : "https://cdn-icons-png.freepik.com/512/266/266134.png"
            }
            alt="profile-img"
          ></ReSizeImg>
        </Grid>
        <Grid item xs={8} md={7}>
          <div>
            <Typography
              variant="mainProfileSamllName"
              letterSpacing={5}
              sx={{
                color: isSelected ? "#26f7fd" : "white",
                transition: "color 1000ms",
              }}
            >
              {data.name}
            </Typography>
          </div>
          <div>
            <p>
              <Typography variant="mainProfileDetail">
                {data.currentAffiliation.club.name || ""}
              </Typography>
            </p>
            <p>
              <Typography variant="mainProfileDetail">
                {`${data.mainPosition || ""} ${data.secondPosition || ""}`}
              </Typography>
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
