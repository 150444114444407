import { Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

export default function RowBox({
  children,
  bgColor,
  padding,
  sidePadding,
  mode,
  title,
  margin,
  isBorder,
}) {
  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        padding: padding,
        margin: margin ? margin : 0,
      }}
    >
      <Box
        sx={{
          width:
            mode === "dark"
              ? {
                  xs: "none",
                  md: "auto",
                  lg: "99%",
                  xl: "99%",
                  xxl: "1700px",
                }
              : {
                  xs: "auto",
                  md: "auto",
                  lg: "auto",
                  xl: "1180px",
                  xxl: "1280px",
                },
          color: "white",
          margin: "0 auto",
          border: isBorder
            ? mode !== "dark"
              ? "1px solid green"
              : "none"
            : "none",
          borderRadius: mode !== "dark" ? "1rem" : "none",
          paddingRight: sidePadding ? sidePadding : "0",
          paddingLeft: sidePadding ? sidePadding : "0",
        }}
      >
        {title}
        {children}
      </Box>
    </Box>
  );
}
