export default function ExtractYouTubeURL(url) {
  if (url.includes("m.youtube")) {
    //모바일용 URL
    const tokens = url.trim().split("v=");
    if (tokens.length === 1) {
      //짧은 경로
      //https://youtu.be/B0z_AM6TgjA?si=RGrusFJD0LPlkv55
      const siTokens = url.trim().split("?si=");
      const videoId = siTokens[0].split("youtu.be/");
      return `https://img.youtube.com/vi/${videoId[1]}/0.jpg`;
    } else {
      return `https://img.youtube.com/vi/${tokens[1].split("&")[0]}/0.jpg`;
    }
  } else {
    //PC용 URL
    const tokens = url.trim().split("?v=");
    if (tokens.length === 1) {
      //https://youtu.be/B0z_AM6TgjA?si=RGrusFJD0LPlkv55
      const siTokens = url.trim().split("?si=");
      const videoId = siTokens[0].split("youtu.be/");
      return `https://img.youtube.com/vi/${videoId[1]}/0.jpg`;
    } else {
      return `https://img.youtube.com/vi/${tokens[1]}/0.jpg`;
    }
  }
}
export function GenerateSNSURL(type, Id) {
  if (type === "INSTAGRAM") {
    if (Id.includes("instagram.com")) {
      return Id;
    } else {
      return `https://www.instagram.com/${Id}`;
    }
  } else if (type === "YOUTUBE") {
    if (Id.includes("youtube.com/")) {
      return Id;
    } else {
      return `https://www.youtube.com/@${Id}`;
    }
  } else if (type === "FACEBOOK") {
    if (Id.includes("facebook.com/")) {
      return Id;
    }
    return `https://www.facebook.com/${Id}`;
  }
}
