import { useEffect } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

const StyledTable = styled.table`
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  caption-side: bottom;

  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 1rem 10px;
    text-align: center;
  }

  tbody tr {
    border-bottom: 1px solid #bbb;
    :nth-of-type(odd) {
    }
    // :hover {
    //   background-color: lightpink;
    // }
  }
  thead > tr {
    background-color: rgb(64, 132, 75);
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
  }
  th {
    padding: 10px 0;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

export default function CoachListLinkTable({ hHeaders, data }) {
  const navigate = useNavigate();
  const handleRowClick = (coachId) => {
    navigate(`/coach-detail/${coachId}`);
  };

  return (
    <StyledTable>
      <thead>
        <tr>
          {hHeaders.map((h, index) => (
            <th key={index}>{h}</th>
          ))}
        </tr>
      </thead>
      {data.length !== 0 ? (
        <tbody style={{ fontSize: "1.1rem" }}>
          {data.map((d) => (
            <tr>
              {d && d.member ? (
                <td
                  onClick={() => handleRowClick(d.member.id)}
                  style={{
                    cursor: "pointer",
                    color: "rgb(64,132,75)",
                    fontWeight: "600",
                  }}
                >
                  {d.member.name}
                </td>
              ) : (
                <td>{d.member.name}</td>
              )}
              <td>{d.member.birthDate}</td>
              <td>{d.positionDescription}</td>
            </tr>
          ))}
        </tbody>
      ) : null}
    </StyledTable>
  );
}
