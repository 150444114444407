import { Paper, Button, Box, Grid } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { API } from "../../config";
import TeamPlayerListTable from "../common/TeamPlayerListTable";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Filter from "../common/Filter";
import { leagueGrade } from "../../recoil/commonEnum";

const center = "39%";
const left = "13%";
const right = "64%";
const positionLoc = [
  { postion: "CF", top: "8%", left: center },
  { postion: "SS", top: "20%", left: center },
  { postion: "AMF", top: "35%", left: center },
  { postion: "DMF", top: "45%", left: center },
  { postion: "LW", top: "20%", left: left },
  { postion: "RW", top: "20%", left: right },
  { postion: "LB", top: "60%", left: left },
  { postion: "CB", top: "70%", left: "27%" },
  { postion: "RB", top: "60%", left: right },
  { postion: "GK", top: "87%", left: center },
];

const position = [
  {
    key: 0,
    name: "ALL",
    description: "ALL",
    include: ["GK", "CB", "LB", "RB", "DMF", "AMF", "LW", "SS", "RW", "CF"],
  },
  {
    key: 1,
    name: "FW",
    description: "FW",
    include: ["LW", "SS", "RW", "CF"],
  },
  { key: 2, name: "MF", description: "MF", include: ["AMF", "DMF"] },
  { key: 3, name: "DF", description: "DF", include: ["LB", "RB", "CB"] },
  { key: 4, name: "GK", description: "GK", include: ["GK"] },
];

const tHeaders = ["NO", "이름", "생년월일", "학년", "신장", "포지션"];

function gradeInfo(league) {
  let gradeFilterItems = leagueGrade[league].map((lg, index) => ({
    key: index + 1,
    name: lg,
    description: lg,
  }));
  return [
    {
      key: 0,
      name: "ALL",
      description: "ALL",
    },
    ...gradeFilterItems,
  ];
}
export default function TeamPlayerNum({ players, league, setTeamBack }) {
  const [originalPlayers, setOriginalPlayers] = useState(players);
  const [filteredPlayers, setFilteredPlayers] = useState(players);

  const [isGradeClicked, setIsGradeClicked] = useState(
    Array(leagueGrade[league].length + 1).fill(false)
  );
  const [isPositionClicked, setIsPositionClicked] = useState(
    Array(position.length).fill(false)
  );

  function calPositionNum(position) {
    return filteredPlayers.filter((p) => p.position === position).length;
  }
  function dataFiltering(filterType, newIsClicked) {
    const gradeFiltering =
      filterType === "GRADE" ? newIsClicked : isGradeClicked;
    const positionFiltering =
      filterType === "POSITION" ? newIsClicked : isPositionClicked;
    let playerFiltering = originalPlayers.map((op) => op);
    if (
      gradeFiltering.filter((igc) => !igc).length !== gradeFiltering.length &&
      gradeFiltering.filter((igc) => igc).length !== gradeFiltering.length
    ) {
      const selectedGrade = gradeFiltering
        .slice(1)
        .map((igc, index) => (igc ? index + 1 : null))
        .filter(Boolean);

      playerFiltering = originalPlayers.filter((op) =>
        selectedGrade.includes(op.schoolYear)
      );
    }
    if (
      positionFiltering.filter((igc) => !igc).length !==
        positionFiltering.length &&
      positionFiltering.filter((igc) => igc).length !== positionFiltering.length
    ) {
      const selectedPosition = positionFiltering
        .slice(1)
        .map((pf, index) => (pf ? position[index + 1].include : null))
        .filter(Boolean)
        .flat();
      playerFiltering = playerFiltering.filter((pf) =>
        selectedPosition.includes(pf.position)
      );
    }

    setFilteredPlayers(playerFiltering);
  }

  return (
    <Box padding="1rem">
      <Box sx={{ margin: "0 0.5rem 0.5rem 0" }}>
        <Box marginRight="1rem">
          <Filter
            categoryName="학년"
            filterType="GRADE"
            filterItem={gradeInfo(league)}
            isClicked={isGradeClicked}
            setIsClicked={setIsGradeClicked}
            dataFiltering={dataFiltering}
            wide={true}
          ></Filter>
        </Box>
        <Box marginRight="1rem">
          <Filter
            categoryName="포지션"
            filterType="POSITION"
            filterItem={position}
            isClicked={isPositionClicked}
            setIsClicked={setIsPositionClicked}
            dataFiltering={dataFiltering}
            wide={true}
          ></Filter>
        </Box>
      </Box>

      <Box>
        <Grid container>
          <Grid item xs={12} md={7}>
            <TeamPlayerListTable
              hHeaders={tHeaders}
              data={filteredPlayers}
              setTeamBack={setTeamBack}
            ></TeamPlayerListTable>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            textAlign="center"
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Box sx={{ padding: " 0 1rem", position: "relative" }}>
              <img
                src={"https://canda23.com/files/field_board.jpg"}
                alt="board-img"
                style={{
                  objectFit: "cover",
                  height: "30rem",
                }}
              ></img>
              {positionLoc.map((pl, index) => (
                <div
                  key={index}
                  style={{
                    width: pl.postion === "CB" ? "14rem" : "7rem",
                    padding: "0.1rem 1rem",
                    textAlign: "center",
                    position: "absolute",
                    top: pl.top,
                    left: pl.left,
                    borderRadius: "5%/10%",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "1rem",
                  }}
                >
                  {`${pl.postion}:${calPositionNum(pl.postion)}명`}
                </div>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
